.primarybutton{
    color: white;
    background-color: #212060;
    border-radius: 1.5vw;
    font-size: clamp(1rem, 2.0vw, 35.0rem);
    padding: 0px 3vw;
    /* width: 20vw; */
}


@media (max-width:767px) {
    .hidesmall{
        display: none;
    }
}