


@font-face {
    font-family: 'A Love of Thunder';
    src: url('../src//assets/A\ Love\ of\ Thunder.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  font-love-thunder {
    font-family: 'A Love of Thunder', sans-serif;
  }
  
  .text-shadow {
    text-shadow: 0px 4px 4px rgba(255, 241, 241, 0.25); 
  }


  @font-face {
    font-family: 'Proxima Nova';
    src: url('../src/assets/fonts/proxima_nova_scosf_complete_family_pack/proxima_nova_scosf_complete_family_pack/Proxima\ Nova\ ScOsf\ Black.otf') format('opentype'); 
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova ScOsf Bold';
  src: url('../src/assets/fonts/proxima_nova_scosf_complete_family_pack/proxima_nova_scosf_complete_family_pack/Proxima\ Nova\ ScOsf\ Bold.otf') format('opentype'); 
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova Condensed Bold';
  src: url('../src/assets/fonts/proxima_nova_scosf_complete_family_pack/proxima_nova_scosf_complete_family_pack/Proxima\ Nova\ ScOsf\ Condensed\ Bold.otf') format('opentype'); 
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Cinzel Bold';
  src: url('../src/assets/fonts/cinzel/Cinzel-Bold.otf') format('opentype'); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova ScOsf Semibold';
  src: url('../src/assets/fonts/proxima_nova_scosf_complete_family_pack/proxima_nova_scosf_complete_family_pack/Proxima\ Nova\ ScOsf\ Semibold.otf') format('opentype'); 
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'cizal-bold';
  src: url('../src/assets/fonts/cinzel/Cinzel-Bold.otf') format('opentype'); 
  font-weight: normal;
  font-style: normal;
}



/* body {
    font-family: 'Proxima Nova', sans-serif; 
} */


/*
#0077ff 
#FFD8FC
#FF5260E5
#FB1422E5
#FB0918DB */




