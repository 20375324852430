.whiteheading{
    word-spacing: 0.5rem;
    font-family: Proxima Nova ScOsf Bold, sans-serif;
    color: #fff;
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
}


@media (min-width:768px) {
    .whiteheading{
        font-size: clamp(1.1rem, 3.5vw, 35.0rem);
    }
    .whitesubheading{
        font-size: clamp(1.2rem, 2.0vw, 35.0rem);
    }
    .whitetextlarge{
        font-size: clamp(1.2rem, 1.2vw, 25.0rem);
    }
}
@media (max-width:767px) {
    .whiteheading{
        font-size: clamp(1.1rem, 5.0vw, 35.0rem);
    }
    .whitesubheading{
        font-size: clamp(1.0rem, 1.5vw, 35.0rem);
    }
    .whitetextlarge{
        font-size: clamp(1.2rem, 5.2vw, 25.0rem);
    }
}